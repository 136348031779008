.sidebar.minimized {
  width: 60px;
  padding-bottom: 10px;
}

.menu-item {
  padding: 7px 15px 7px 15px;
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  display: block;
  transition: background-color 0.3s ease;
}

.menu-item i {
  margin-right: 10px;
  margin-bottom: 10px;
}

.menu-item:hover {
  background-color: #555;
  border-radius: 5px;
}

.side-bar-icon {
  height: 30px;
}

.side-bar-icons {
  height: 30px;
  display: inline;
}

.icon-button {
  margin-top: auto;
  align-self: flex-end;
  padding: 20px;
}

.sidebar-divider {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 20px;
}

.label {
  opacity: 1;
  transition: opacity 0.5s ease;
  font-size: 16px;
  margin-left: 15px;
}
