.header {
    background-color: #04254e;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 78px;
    transition: width 0.5s;
}

.header button {
    margin-right: 20px;
}


.logo {
    margin-left: 20px;
    padding: 8px;
    width: 200px;
}

.logo:hover {
    cursor: pointer;
    width: 190px;
}

.vodium-logo {
    width: 200px;
    height: 42px;
}

.vodium-logo:hover {
    width: 195px;
}