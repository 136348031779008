.footer {
  background-color: #04254e;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 60px;
  font-size: 12px;
}
.footer .column {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  border-width: 0px 0px 0px 1px;
  border-right: 1px #fff;
}
.footer .column.icon {
  border-width: 0px;
  width: 8px;
  padding: 0px;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 0px;
}

.footer .column:nth-child(1) {
  width: 300px;
  border-width: 0px;
}
.footer .column:nth-child(2) {
  width: 100px;
}
.footer .column:nth-child(3) {
  width: 150px;
}
.footer .column:nth-child(4) {
  width: 120px;
}
