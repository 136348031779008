.submit-button {
    margin-left: 20px;
}

.form-div {
    margin: 20px;
}

.form-input {
    margin-top: 5px;
    font-size: 13px;
}

.form-div.column.textarea-div {
    width: 80%;
}

.textarea-div textarea {
    height: 200px;
}

.form-div.column {
    width: 80%;
}

.contact-page .row {
    align-items: start;
}

.icon {
    height: 25px;
    margin-left: 50px;
    margin-top: 8px;
}