.license-management-page {
  max-width: 1350px;
  width: 100%;
  /* background-color: #f7f7f7; */
}

.pic-container {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background: #333;
  text-align: center;
  margin: 5px;
  margin-left: 10px;
}

.pic-initials {
  width: 50px;
  text-align: center;
  color: white;
  font-size: 16px;
  line-height: 50px;
}

.profile-list-details {
  width: 100%;
  font-size: 12px;
  margin-left: 5px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5954a4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5954a4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.table-div {
  /* max-height: 400px; */
}

.table-list {
  margin: 10px;
  margin-top: 20px;
  margin-left: 25px;
}

.user-column {
  width: 50%;
}

.role-column {
  width: 20%;
}

.message-column {
  width: 20%;
}

.license-column {
  width: 10%;
}

tr {
  margin: 12px;
}

td {
  padding: 12px;
  border-top: solid 1px #d9d7d7;
}

th {
  padding-bottom: 10px;
}

tbody {
  max-height: 400px;
  overflow: auto;
}

.search-field {
  width: 400px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url("../../assets/search_icon.svg");
  background-position: 10px 10px;
  background-size: 22px 30px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
}

.search-field:focus {
  outline: none !important;
  border: 1px solid #5954a4;
  box-shadow: 0 0 5px #5954a4;
}

.count-container {
  margin: 5px;
}

select {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select:focus {
    outline: none !important;
    border: 1px solid #5954a4;
    box-shadow: 0 0 2px #5954a4;
  }
