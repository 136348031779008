.description {
    max-width: 400px;
    /* font-size: 18px; */
}

.vodium.row {
    align-items: center;
}

.vodium .column {
    width: 48%;
    padding: 10px;
    align-items: center;
}

.title-description {
    /* font-size: 32px; */
    padding-bottom: 10px;
}

video {
    border: 5px;
    border-color: white;
}

.view-button {
    margin-top: 20px;
}

.vodium .none-gradient {
    font-size: 30px;
}

.vodium .gradient-text {
    font-size: 30px;
}